.App {
  text-align: left;
  font-family: 'Open Sans', sans-serif;
}

.App-logo {
  /*animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;*/
}

.App-header {
  background: url('../src/images/hero background.png') no-repeat; /** center center **/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
  /*opacity: 0.6;*/
  background-size: cover;
  width: 100%;
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-footer {
  /*background-color: #f1f1f1;
  background-color:rgba(236,240,242,.7);*/
  background-color:rgba(83,153,141, .5);
  text-align: center;
  font-size: 0.875em;
  padding: 2em;
  font-weight: 700;
  margin-bottom: -2em;
}

@media screen and (max-width: 415px) {
  .App-header {
    background-image: url('../src/images/hero background.png');
  }
}