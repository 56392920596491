.Main {
    text-align: left;
}

.MuiAppBar-colorPrimary.Custom-tab {
    background-color: rgba(83,153,141, .5);
    color: black;
    font-family: 'Open Sans';
}

.Card-image {
    height: 100px;
    padding-top: 56.25%;
    margin-top: 30px;
}

.Card-image:hover {
    background-color: rgba(236,240,242,.7);
    
}

a {
    text-decoration: none;
    color: black;
    font-weight: bold;
}

.Outbound-link:hover {
    color:rgba(83,153,141, .5);
}

.MuiButton-root:hover {
    background-color: rgba(83,153,141, .5) !important;
}


.MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit {
    font-family: 'Open Sans';
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit.MuiSelected {
    border-bottom: 5px solid rgba(83,153,141, .5) ;
}

#simple-tabpanel-0 {
    font-family: 'Open Sans';
}

.MuiPaper-root.Latest-blog-post {
    text-align: center;
    background-color: rgba(236,240,242,.35);
}

.MuiPaper-root {
    font-family: 'Open Sans';
}
