.Contact-container {
    background-color:white; /*rgba(0,129,137,0.20);*/
    padding-top: 3em;
    padding-left: 8em;
    padding-right: 8em;
}

.Contact-icon {
    width:50%;
    height: auto;
    padding-left: 8em;
}

.Contact-text {
    text-align: 'left';
    font-weight: 'normal';
    font-family: 'Open Sans';
}


@media screen and (max-width: 415px) {
    .Contact-container {
        padding-left: 0em;
        padding-right: 0em;
    }

    .Contact-text {
        font-size: 1em;
    }

}

@media screen and (max-width: 900px) {
    .Contact-icon {
        width: 100%;
        padding-left: 0em;
    }

}

