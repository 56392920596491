.About-container {
    /*background-color: rgba(236,240,242,.35);*/
    background-color: white;
    /*padding-top: 3em;
    padding-left: 8em;
    padding-right: 8em;
    padding-bottom: 3em;*/
}

.Blog-post-image {
    width: 50%;

    /*box-shadow: 0 5px 15px -8px rgba(0,0,0,0.24), 0 8px 10px -5px rgba(0,0,0,0.2);
    background-image: url('../images/new icons/atom.png');*/
}

.About-card {
    border-top: 5px solid rgba(83,153,141, .5);
    max-width: 350px;
}

.About-card:hover {
    transform: translate3d(0, -5px, 0);
    box-shadow: 0 1.5rem 2.5rem rgba(22, 28, 45, .1),0 .3rem .5rem -.50rem rgba(22,28,45,.05) !important;
}