.Work-container {
    background-color: white;
    padding-top: 3em;
    padding-bottom: 3em;
    padding-left: 6em;
    padding-right: 6em;
}

.Work-card {
    border-top: 5px solid rgba(83,153,141, .5);
    max-width: 300px;
}

.Work-card:hover {
    transform: translate3d(0, -5px, 0);
    box-shadow: 0 1.5rem 2.5rem rgba(22, 28, 45, .1),0 .3rem .5rem -.50rem rgba(22,28,45,.05) !important;
}

@media screen and (max-width: 415px) {
    .Work-container {
        padding-top: 3em;
        padding-bottom: 3em;
        padding-left: 0em;
        padding-right: 0em;
    }
}