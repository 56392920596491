.Menu {
    padding-top: 2em;
    padding-bottom: 4.3em;
    /*background-color: rgba(236,240,242,.35);*/
    background-color: white;
    flex-wrap: wrap;
}


.MuiButtonBase-root.MuiIconButton-root:hover {
    background-color:rgba(83,153,141, .5) ;
}

.MuiList-root.MuiMenu-list.MuiList-padding {
    padding-left: 1em;
    padding-right: 1em;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-text {
    font-family: 'Open Sans';

}

.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button {
    font-family: 'Open Sans';
    font-size: 0.875rem;
}
