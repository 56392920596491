.Skills-container {
    /*background-color: rgba(236,240,242,.35);*/
    background-color: white;
    padding-top: 2em;
    padding-left: 8em;
    padding-right: 8em;
}

.Skills-text {
    font-family: 'Open Sans';
}

.Backend-container {
    padding-right: 2em;
}

/* MEDIA QUERIES FOR TABLETS */
@media screen and (max-width: 900px) {
    .Skills-container {
        padding-left: 1em;
        padding-right: 1em;
    }

    .Backend-container {
        padding-right: 2em;
    }
}

/* MEDIA QUERIES FOR PHONES */
@media screen and (max-width: 415px) {
    .Skills-container {
        padding-top: 1em;
        padding-left: 0em;
        padding-right: 0em;
    }

    .Backend-container {
        padding-right: 1em;
    }

    .Skills-text {
        font-size: 0.75em;
    }
}